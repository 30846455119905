import type MenuI from '@/types/Navigation/MenuI'
import type MenuItemI from '@/types/Navigation/MenuItemI'
import fetchGql from '@/utils/fetchGql'
import { useCacheStorage } from '@/composables/useSessionStorage'
import { isEn } from "@/utils/helpers";

const { getCache } = useCacheStorage()

const query = gql`
  query FooterItems($language: String) {
    FooterItems(starts_with: $language, resolve_links: "url", by_slugs: "corporate/shared/footer") {
      items {
        content {
          allRightsReserved
          allRightsReservedHref {
            url
          }
          contactHref {
            url
          }
          contactText
          menu
          socials
        }
      }
    }
  }
`

const parseData = (data: any, locale: string) => {
  const menu: MenuI[] = []
  let socials = []
  let contactUs: any = []

  data[0].content.menu.forEach((menuItem: MenuItemI) => {
    if (menuItem.subItems?.length) {
      const parsedItems: MenuI[] = []
      menuItem.subItems.forEach((item: MenuItemI) => {
        parsedItems.push({
          title: item.title,
          url: getUrl(item, locale),
          linkClass: 'text-bt-ui-white/60'
        })
      })
      menu.push({
        title: menuItem.title,
        items: parsedItems,
        url: getUrl(menuItem, locale),
        linkClass: 'text-bt-ui-white/60'
      })
    } else {
      menu.push({
        title: menuItem.title,
        url: getUrl(menuItem, locale),
        linkClass: 'text-bt-ui-white/60'
      })
    }
  })

  socials = data[0].content.socials || []

  contactUs = {
    text: data[0].content.contactText,
    href: data[0].content.contactHref
  }

  const allRightsReserved = {
    text: data[0].content.allRightsReserved,
    href: data[0].content.allRightsReservedHref.url
  }

  return { menu, socials, contactUs, allRightsReserved }
}

const getUrl = (item: MenuItemI, locale: string) => {
  if (item.redirect?.url) {
    return formatSbPath(item.redirect.url, locale)
  }

  if (item.redirect?.story) {
    return formatSbPath(item.redirect?.story?.full_slug, locale)
  }

  return '/'
}

export const useFetchFooter = () => {
  const fetchFooter = async (language: string) => {
    const cache = await getCache(`footer-${language}`)

    const variables = {
      language: isEn(language) ? '' : `${language}/*`
    }

    if (cache.value) return cache.value

    return fetchGql(query, variables).then(async (response: any) => {
      return (cache.value = parseData(response.FooterItems.items, variables.language))
    })
  }

  return { fetchFooter }
}
