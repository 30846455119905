<script lang="ts" setup>
  import '@bt/design-system/style.css'
  import { OverlaySpinner } from '@bt/design-system'
  import { storeToRefs } from 'pinia'
  import { useAppStore } from '@/stores'
  import StoryBlok from '@/services/storyblok'
  import { useFetchMenu } from '@/composables/useFetchMenu'
  import { useFetchFooter } from '@/composables/useFetchFooter'
  import formatSbPath from '@/utils/formatSbPath'

  const appStore = useAppStore()
  const { slugs, cmsData, cmsPath, menuData, loading, cmsPathParams, errorPage, activeMainNav } = storeToRefs(appStore)

  const route = useRoute()
  const { push: routerPush } = useRouter()
  const { locales, locale, setLocale } = useI18n()
  const { fetchMenu } = useFetchMenu()
  const { fetchFooter } = useFetchFooter()
  const footerData = ref()
  const NEWS_ROOM_NAMES = [
    'newsroom',
    'preses-telpa',
    'pranesimai-spaudai',
    'lehdistotiedotteet',
    'pressiteated',
    'pressemitteilungen'
  ]

  cmsPath.value = route.path === `/${locale.value}` ? '' : route.path.replace(`/${locale.value}/`, '')

  if (errorPage.value) {
    cmsPath.value = activeMainNav.value
    errorPage.value = false
  }

  const { data, refresh } = await useAsyncData('cmsData', async () => await StoryBlok.pull(cmsPath.value, locale.value))
  cmsData.value = data

  const setSlugs = () => {
    locales.value.forEach((locale: any) => {
      if (locale.code === 'en') {
        slugs.value[locale.code] = `/${locale.code}/${formatSbPath(cmsData?.value?.default_full_slug)}`
      }
      const translatedSlug = cmsData?.value?.translated_slugs?.find((slug: any) => slug.lang === locale.code)

      if (translatedSlug) {
        slugs.value[locale.code] = `/${locale.code}/${formatSbPath(translatedSlug.path)}`
      }
    })
  }

  const onLocaleChange = (lang: string) => {
    if (lang === locale.value) return

    loading.value = true

    setLocale(lang).then(async () => {
      cmsPath.value = await refreshNuxtData()
      await getMenuData()
      await getFooterData()
      appStore.activeMainNav = route.path.replace(`/${locale.value}/`, '')
      loading.value = false
    })
  }

  const getFooterData = async () => {
    footerData.value = await fetchFooter(locale.value)
  }

  const getMenuData = async () => {
    try {
      const data = await fetchMenu(locale.value)
      const currentView = formatSbPath(slugs.value[locale.value], locale.value)

      menuData.value = data.map((menuItem: any) => {
        if (menuItem.subItems) {
          menuItem.subItems = menuItem.subItems.map((subData: any) => ({
            ...subData,
            isActive: subData.key === currentView
          }))
        }

        menuItem.isActive = menuItem.key === (currentView || '/')

        return menuItem
      })
    } catch (e) {
      console.error(e)
    }
  }

  const getPageData = (item: any) => {
    const path = item?.currentItem?.key ?? item

    if (path.startsWith('http')) {
      return navigateTo(path, {
        external: true,
        open: {
          target: item.target
        }
      })
    }

    loading.value = true

    try {
      cmsPath.value = formatSbPath(path, locale.value)
      // cmsData.value = []

      /*
      AnchorWrapper on load pushes anchor data to anchorList, so we need to clear it to get rid of old data
      otherwise we get duplicates on language switch
      */

      appStore.anchorList = []

      refresh().then(() => {
        setSlugs()
        getMenuData()
      })

      routerPush({ path: `/${locale.value}/${cmsPath.value}`, query: cmsPathParams.value })
    } catch (e) {
      console.error(e)
    }

    loading.value = false
  }

  getMenuData()
  setSlugs()
  getFooterData()

  const isNewsRoomPage = (path: string) => {
    const routePath = formatSbPath(path, locale.value)
    return NEWS_ROOM_NAMES.includes(routePath)
  }

  const UNTRANSLATED_SLUGS = ['esg']

  const untranslatedSlugException = (slug: string) => {
    return route.path.includes(slug) && cmsPath.value.includes(slug)
  }

  const anchorHasChanged = (newPath: string, oldPath: string) => {
    const stripAnchor = (path: string): string => path.split('#')[0]

    const newBase = stripAnchor(newPath)
    const oldBase = stripAnchor(oldPath)

    const newHasAnchor = newPath.includes('#')
    const oldHasAnchor = oldPath.includes('#')

    return (
      (newHasAnchor && newBase === oldPath) ||
      (oldHasAnchor && oldBase === newPath) ||
      (newHasAnchor && oldHasAnchor && newBase === oldBase)
    )
  }

  watch(
    () => route.fullPath,
    (value, oldValue) => {
      if (
        anchorHasChanged(value, oldValue) ||
        (cmsPath.value &&
          !UNTRANSLATED_SLUGS.some((slug) => untranslatedSlugException(slug)) &&
          cmsPath.value.replace(`/${locale.value}/`, '') === route.path.replace(`/${locale.value}/`, ''))
      ) {
        return
      }

      cmsData.value = []

      if (!isNewsRoomPage(route.path)) {
        appStore.resetAvailableLocales()

        if (!appStore.availableLocales.includes(locale.value)) {
          return navigateTo(route.path.replace(`/${locale.value}/`, '/en/'))
        }
      }

      appStore.setCmsPath(route.path, route.query)
      getPageData(cmsPath.value)
    },
    {
      deep: true
    }
  )
  const isIndexPage = computed(() => cmsData?.value?.content?.layout === 'index-page')

  useHead(() => {
    return {
      htmlAttrs: {
        lang: locale.value
      },
      script: [
        {
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': 'df835a4e-1a33-4cb2-9fa9-a64393bcdf68',
          'data-culture': locale.value
        }
      ]
    }
  })

  const validPageLayouts = ['page-default', 'page-section']
  const currentPageLayout = computed(() =>
    validPageLayouts.includes(cmsData.value?.content?.layout) ? cmsData.value?.content?.layout : 'page-default'
  )
</script>

<template>
  <div>
    <ClientOnly>
      <OverlaySpinner
        v-if="loading"
        size="lg"
      />
    </ClientOnly>
    <NuxtLayout
      :name="currentPageLayout"
      @change="getPageData"
    >
      <template #navigation-header="{ hideBreadcrumb, isSectionPage }">
        <NavigationHeader
          :is-index-page="isSectionPage || isIndexPage"
          :hide-breadcrumbs="hideBreadcrumb || isIndexPage"
          @refresh-all-data="onLocaleChange"
        />
      </template>
      <template #footer>
        <MainFooter
          class="footer"
          :groups="footerData?.menu"
          :social-media="footerData?.socials"
        >
          <template #all-rights-reserved>
            <NuxtLink :to="footerData?.allRightsReserved?.href">
              {{ footerData?.allRightsReserved?.text }}
            </NuxtLink>
          </template>
          <template #contact>
            <NuxtLink
              :to="footerData?.contactUs?.href.url"
              class="capitalize"
            >
              {{ footerData?.contactUs?.text }}
            </NuxtLink>
          </template>
        </MainFooter>
      </template>
    </NuxtLayout>
  </div>
</template>

<style lang="scss">
  @use 'assets/styles/common' as c;

  .app {
    display: flex;
    flex-direction: column;
  }

  .capitalize {
    text-transform: uppercase;
    text-decoration: underline;
  }

  .content-wrapper {
    max-width: c.rem(1120);
    margin: auto;
    width: 100%;
  }

  .footer {
    animation: fadeIn;
    animation-duration: 2s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
</style>
