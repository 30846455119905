
// @ts-nocheck


export const localeCodes =  [
  "en",
  "lv",
  "lt",
  "et",
  "fi",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_en_js" */), cache: true }],
  "lv": [{ key: "../locales/lv.js", load: () => import("../locales/lv.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_lv_js" */), cache: true }],
  "lt": [{ key: "../locales/lt.js", load: () => import("../locales/lt.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_lt_js" */), cache: true }],
  "et": [{ key: "../locales/et.js", load: () => import("../locales/et.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_et_js" */), cache: true }],
  "fi": [{ key: "../locales/fi.js", load: () => import("../locales/fi.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_fi_js" */), cache: true }],
  "de": [{ key: "../locales/de.js", load: () => import("../locales/de.js" /* webpackChunkName: "locale__builds_common_airbaltic_com_company_locales_de_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "dir": "ltr",
      "label": "English",
      "title": "English",
      "key": "en",
      "files": [
        "locales/en.js"
      ]
    },
    {
      "code": "lv",
      "iso": "lv-LV",
      "dir": "ltr",
      "label": "Latviešu",
      "title": "Latviešu",
      "key": "lv",
      "files": [
        "locales/lv.js"
      ]
    },
    {
      "code": "lt",
      "iso": "lt-LT",
      "dir": "ltr",
      "label": "Lietuviškai",
      "title": "Lietuviškai",
      "key": "lt",
      "files": [
        "locales/lt.js"
      ]
    },
    {
      "code": "et",
      "iso": "et-ET",
      "dir": "ltr",
      "label": "Eesti",
      "title": "Eesti",
      "key": "et",
      "files": [
        "locales/et.js"
      ]
    },
    {
      "code": "fi",
      "iso": "fi-FI",
      "dir": "ltr",
      "label": "Suomeksi",
      "title": "Suomeksi",
      "key": "fi",
      "files": [
        "locales/fi.js"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "dir": "ltr",
      "label": "Deutsch",
      "title": "Deutsch",
      "key": "de",
      "files": [
        "locales/de.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://company.airbaltic.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "dir": "ltr",
    "label": "English",
    "title": "English",
    "key": "en",
    "files": [
      {
        "path": "locales/en.js"
      }
    ]
  },
  {
    "code": "lv",
    "iso": "lv-LV",
    "dir": "ltr",
    "label": "Latviešu",
    "title": "Latviešu",
    "key": "lv",
    "files": [
      {
        "path": "locales/lv.js"
      }
    ]
  },
  {
    "code": "lt",
    "iso": "lt-LT",
    "dir": "ltr",
    "label": "Lietuviškai",
    "title": "Lietuviškai",
    "key": "lt",
    "files": [
      {
        "path": "locales/lt.js"
      }
    ]
  },
  {
    "code": "et",
    "iso": "et-ET",
    "dir": "ltr",
    "label": "Eesti",
    "title": "Eesti",
    "key": "et",
    "files": [
      {
        "path": "locales/et.js"
      }
    ]
  },
  {
    "code": "fi",
    "iso": "fi-FI",
    "dir": "ltr",
    "label": "Suomeksi",
    "title": "Suomeksi",
    "key": "fi",
    "files": [
      {
        "path": "locales/fi.js"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "dir": "ltr",
    "label": "Deutsch",
    "title": "Deutsch",
    "key": "de",
    "files": [
      {
        "path": "locales/de.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
