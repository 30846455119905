<script lang="ts" setup>
  import { Breadcrumb, Navigation } from '@bt/design-system'
  import type ICrumbs from '@/types/Navigation/ICrumbs'
  import { useFetchHeaderMenu } from '@/composables/useFetchHeaderMenu'
  import { useBreakpoints } from '@/composables/useBreakpoints'
  import { useAppStore } from '@/stores'

  const { locale, t } = useI18n()
  const { push } = useRouter()
  const { fetchHeaderMenu } = useFetchHeaderMenu()
  const appStore = useAppStore()
  const { isMobile, isDesktop } = useBreakpoints()
  const route = useRoute()
  const navigation = ref([])

  const emit = defineEmits(['refreshAllData'])

  fetchHeaderMenu(locale.value)
    .then((response) => (navigation.value = response))
    .catch((e) => console.error(e))
    .finally(() => (appStore.indexAnimations.headerNavIsLoaded = true))

  interface Props {
    hideBreadcrumbs?: boolean
    isIndexPage?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    hideBreadcrumbs: false,
    isIndexPage: false
  })

  const menuDataActive = computed(() => {
    if (!navigation.value) {
      return {}
    }
    return navigation.value.map((data: any) => {
      data.isActive = data.key === appStore.activeMainNav
      return data
    })
  })

  const breadCrumbs = computed(() => {
    let path = ''

    const crumbs: ICrumbs[] = [
      {
        title: t(`reusable.home`),
        key: path
      }
    ]

    if (appStore?.slugs[locale.value]?.length) {
      const slug = formatSbPath(appStore.slugs[locale.value], locale.value).split('/')

      slug.forEach((item: string) => {
        if (!item) return
        path = path === '' ? item : path + '/' + item

        crumbs.push({
          title: getTitleFromSlug(item),
          key: path
        })
      })
    }

    return crumbs
  })

  const showAnimation = computed(
    () => isDesktop.value && navigation.value && props.isIndexPage && appStore.indexAnimations.headerNavIsLoaded
  )

  const getTitleFromSlug = (item: String) => {
    return item.replaceAll('-', ' ')
  }

  const onMenuClick = (item: any) => {
    if (item?.target === '_blank' && isDesktop.value) {
      return
    }

    appStore.activeMainNav = item.key

    const external = item.key.startsWith('http')

    return navigateTo(item.key, {
      external
    })
  }

  const onBreadCrumbClick = (item: any) => {
    appStore.activeMainNav = item.key

    push(`/${locale.value}/${item.key}`)
  }

  const refreshAllData = (lang: string) => {
    emit('refreshAllData', lang)
  }

  onMounted(() => {
    const path = route.path.split('/')
    appStore.activeMainNav = path.pop() ?? '/'
  })
</script>

<template>
  <ClientOnly>
    <div
      :class="{ 'index-page-header !mb-0': isIndexPage, animated: showAnimation }"
      class="header-wrapper content-wrapper"
    >
      <div class="navigation-wrapper">
        <NavigationMobileHeader
          v-if="!isDesktop"
          :items="menuDataActive"
          @change="onMenuClick"
          @refresh-all-data="refreshAllData"
        />
        <Navigation
          v-else
          class="w-full lg:m-auto lg:px-4 lg:py-0 xl:p-0"
        >
          <template #logo>
            <NuxtLink :to="`/${locale}/`">
              <img
                alt="airBaltic logo"
                class="bt-logo"
                height="27"
                src="/bt-logo.svg"
                title="airBaltic logo"
                width="120"
              />
            </NuxtLink>
          </template>
          <template #items="{ activeClass, inactiveClass, defaultClass }">
            <NuxtLink
              v-for="item in menuDataActive"
              :key="item.key"
              :class="[defaultClass, item.isActive ? activeClass : inactiveClass]"
              :target="item.target"
              :to="item.key"
              @click="onMenuClick(item)"
            >
              {{ item.title }}
            </NuxtLink>
          </template>
          <template #right-side>
            <GeneralLanguageSwitcher @refresh-all-data="refreshAllData" />
          </template>
        </Navigation>
      </div>
      <div
        v-if="!isMobile && !hideBreadcrumbs"
        class="breadcrumbs"
      >
        <Breadcrumb
          :items="breadCrumbs"
          @change="onBreadCrumbClick"
        />
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .index-page-header {
    width: 100%;
    height: auto;
    max-width: unset;

    @include desktop-up {
      background: rgb(255 255 255 / 60%);
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      z-index: 99;
      backdrop-filter: blur(rem(32));
    }

    &.animated {
      animation-duration: 1s;
      animation-name: slideIn;
    }

    .navigation-wrapper {
      max-width: rem(1120);
      margin-bottom: 0;
    }
  }

  .header-wrapper {
    border-bottom: 1px solid $border-grey;
    margin-bottom: rem(20);
    margin-top: 0;

    @include tablet-up {
      border: none;
      margin-bottom: 0;
    }
  }

  .navigation-wrapper {
    margin: auto rem(16) rem(8);

    @include tablet-up {
      margin: auto rem(16) rem(24);
    }

    @include desktop-up {
      margin: auto auto rem(40);
      justify-content: space-between;
      display: flex;
    }
  }

  .bt-logo {
    margin-top: rem(40);
  }

  .navigation-left {
    margin: rem(40) 0 0;
  }

  .breadcrumbs {
    margin: auto rem(16);

    @include desktop-up {
      margin: auto auto rem(16);
    }
  }

  @keyframes slideIn {
    from {
      margin-top: rem(-142);
    }

    to {
      margin-top: 0;
    }
  }
</style>
