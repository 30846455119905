<script lang="ts" setup>
  import { useFetchFooter } from '@/composables/useFetchFooter'
  import { useBreakpoints } from '@/composables/useBreakpoints'
  import { Text } from '@bt/design-system'
  import { useAppStore } from '@/stores'
  import { storeToRefs } from 'pinia'
  import image404 from '/404.svg'
  import image500 from '/500.svg'

  const appStore = useAppStore()
  const { cmsPath, errorPage } = storeToRefs(appStore)
  const { fetchFooter } = useFetchFooter()
  const { isMobile } = useBreakpoints()

  const { locale, t, setLocale } = useI18n()
  const { error } = defineProps({
    error: {
      type: Object,
      required: true
    }
  })

  onMounted(() => {
    errorPage.value = true
  })

  const errorImage = computed(() => {
    if (error.statusCode === 404) {
      return image404
    }
    if (error.statusCode === 500) {
      return image500
    }
  })
  const footerData = ref()

  const getErrorMessage = () => {
    if (error.statusCode === 404) {
      return 'errorPage.message.404'
    }
    if (error.statusCode === 500) {
      return 'errorPage.message.500'
    }

    return 'errorPage.message.generic'
  }

  const errorMessage = getErrorMessage()

  const getFooterData = async () => {
    footerData.value = await fetchFooter(locale.value)
  }

  getFooterData()

  const onLocaleChange = (lang: string) => {
    if (lang === locale.value) return

    errorPage.value = false

    setLocale(lang).then(async () => {
      cmsPath.value = await refreshNuxtData()
      await getFooterData()
    })
  }
</script>

<template>
  <NavigationHeader
    :hide-breadcrumbs="true"
    @refresh-all-data="onLocaleChange"
  />
  <NuxtLayout name="full">
    <div class="error-page">
      <img
        :alt="$t(`${errorMessage}`)"
        :height="isMobile ? 127 : 217"
        :src="errorImage"
        :width="isMobile ? 328 : 558"
        loading="lazy"
      />
      <Text
        :text="$t(`${errorMessage}`)"
        class="error-message"
        tag="span"
        type="lead"
      />
    </div>
  </NuxtLayout>
  <MainFooter
    :groups="footerData?.menu"
    :social-media="footerData?.socials"
  >
    <template #all-rights-reserved>
      <NuxtLink :to="footerData?.allRightsReserved?.href">
        {{ footerData?.allRightsReserved?.text }}
      </NuxtLink>
    </template>
    <template #contact>
      <NuxtLink :to="footerData?.contactUs?.href.url">
        {{ footerData?.contactUs?.text }}
      </NuxtLink>
    </template>
  </MainFooter>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: rem(96) rem(16);

    @include desktop-up {
      margin: rem(96) 0;
    }
  }

  .error-message {
    margin: rem(32) rem(40) rem(40);
    text-align: center;

    @include desktop-up {
      margin: rem(48) 0;
    }
  }
</style>
