export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
  const { $i18n } = useNuxtApp()
  const locale = $i18n.locale

  if (to.path.endsWith('/' + locale.value)) {
    const { path, query, hash } = to
    const nextRoute = { path: path + '/', query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
