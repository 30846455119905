import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const isProd = config.public.env !== 'production'

  Sentry.init({
    app: nuxtApp.vueApp,
    debug: isProd,
    dsn: config.public.sentry_dsn,
    environment: config.public.sentry_env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
        tracePropagationTargets: ['investors.airbaltic.com' /* , 'local.airbaltic.com' */]
      })
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    tracesSampleRate: 0.01
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
