export default function (path, locale) {
  const config = useRuntimeConfig()
  const testEnv = config.public.env !== 'production'

  const localeReg = new RegExp('^/' + locale + '/')
  const localeReg2 = new RegExp('^' + locale + '/')
  const investorsReg = /investors-airbaltic-com\//
  const companyReg = /company-airbaltic-com\//
  const sharedReg = /shared/
  const corporateReg = /^corporate\//

  if (path) {
    if (testEnv) {
      path = replaceLiveUrl(path, config.public)
    }

    return path
      .replace(localeReg, '')
      .replace(localeReg2, '')
      .replace(corporateReg, '')
      .replace(sharedReg, '')
      .replace(investorsReg, '')
      .replace(companyReg, '')
      .replace(/^\/|\/$/g, '')
  }

  return ''
}

const replaceLiveUrl = (path, config) => {
  const liveInvestorsUrl = 'https://investors.airbaltic.com'
  const liveCompanyUrl = 'https://company.airbaltic.com'

  const testInvestorsUrl = config.investorsUrl
  const testCompanyUrl = config.companyUrl

  if (path.includes(liveInvestorsUrl)) {
    path = path.replace(liveInvestorsUrl, testInvestorsUrl)
  }
  if (path.includes(liveCompanyUrl)) {
    path = path.replace(liveCompanyUrl, testCompanyUrl)
  }

  return path
}
