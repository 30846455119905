import type { Ref } from 'vue'
import { ref, onMounted, onUnmounted } from 'vue'

export const useBreakpoints = (): { isMobile: Ref<boolean>; isTablet: Ref<boolean>; isDesktop: Ref<boolean> } => {
  const isDesktop = ref(false)
  const isTablet = ref(false)
  const isMobile = ref(false)

  const updateMedia = () => {
    isDesktop.value = window.matchMedia('(min-width: 1024px)').matches
    isTablet.value = window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches
    isMobile.value = window.matchMedia('(max-width: 767px)').matches
  }

  onMounted(() => {
    updateMedia()
    window.addEventListener('resize', updateMedia)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateMedia)
  })

  return { isDesktop, isTablet, isMobile }
}
