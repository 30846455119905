import { default as _91_46_46_46slug_93O8tAwn89sWMeta } from "/builds/common/airbaltic-com/company/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___lv",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/lv/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___lt",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/lt/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___et",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/et/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___fi",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/fi/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93O8tAwn89sWMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93O8tAwn89sWMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93O8tAwn89sWMeta || {},
    alias: _91_46_46_46slug_93O8tAwn89sWMeta?.alias || [],
    redirect: _91_46_46_46slug_93O8tAwn89sWMeta?.redirect,
    component: () => import("/builds/common/airbaltic-com/company/pages/[...slug].vue").then(m => m.default || m)
  }
]