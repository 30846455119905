import type MenuI from '@/types/Navigation/MenuI'
import type MenuItemI from '@/types/Navigation/MenuItemI'
import { useAppStore } from '@/stores'
import fetchGql from '@/utils/fetchGql'
import { useCacheStorage } from '@/composables/useSessionStorage'
import { isEn } from "@/utils/helpers";

const { getCache } = useCacheStorage()

const query = gql`
  query MenuItems($language: String) {
    MenuItems(starts_with: $language, resolve_links: "url", by_slugs: "corporate/shared/company-header-menu") {
      items {
        content {
          items
        }
      }
    }
  }
`

const parseData = (data: any, locale: string) => {
  const result: MenuI[] = []

  data[0].content.items.forEach((menuItem: MenuItemI) => {
    let target = '_self'
    const itemUrl = menuItem.redirect?.story?.full_slug || menuItem.redirect?.url
    if (menuItem.redirect?.target) {
      target = menuItem.redirect.target
    }

    result.push({
      title: menuItem.title,
      key: formatSbPath(itemUrl, locale) || '/',
      target
    })
  })

  return result
}

export const useFetchHeaderMenu = () => {
  const appStore = useAppStore()
  const fetchHeaderMenu = async (language: string) => {
    const cache = await getCache(`header-nav-${language}`)

    const variables = {
      language: isEn(language) ? '' : `${language}/*`
    }

    if (cache.value) {
      appStore.loading = false
      return cache.value
    }

    return fetchGql(query, variables).then(async (response: any) => {
      return (cache.value = parseData(response.MenuItems.items, variables.language))
    })
  }

  return { fetchHeaderMenu }
}
