import { defineStore } from 'pinia'
import type MenuItemI from '@/types/Navigation/MenuItemI'

const LOCALE_LIST = ['en', 'lv']

interface IAnchorListItem {
  id: string
  title: string
}

interface AppStoreI {
  loading: boolean
  slugs: any
  activeMainNav: string
  cmsData: any
  menuData: MenuItemI[]
  cmsPath: any
  cmsPathParams: any
  errorPage: boolean
  indexAnimations: {
    headerNavIsLoaded: boolean
  }
  availableLocales: string[]
  anchorList: IAnchorListItem[]
}

export const useAppStore = defineStore('app', {
  state: (): AppStoreI => ({
    loading: false,
    slugs: {},
    activeMainNav: '/',
    cmsData: {},
    menuData: [],
    cmsPath: '',
    cmsPathParams: {},
    errorPage: false,
    indexAnimations: {
      headerNavIsLoaded: false
    },
    availableLocales: LOCALE_LIST,
    anchorList: []
  }),
  actions: {
    setCmsPath(path: string, queryParams = {}) {
      this.cmsPath = path
      this.cmsPathParams = queryParams
    },
    setAvailableLocales(value: string[]) {
      this.availableLocales = value
    },
    resetAvailableLocales() {
      this.availableLocales = LOCALE_LIST
    }
  }
})
