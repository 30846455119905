import { StorageSerializers, useSessionStorage } from '@vueuse/core'

export const useCacheStorage = () => {
  const getCache = async (key: string) => {
    return useSessionStorage(key, null, {
      serializer: StorageSerializers.object
    })
  }

  return { getCache }
}
