<script lang="ts" setup>
  import { LanguageSwitcher } from '@bt/design-system'
  import { availableLocales, setPathByLocale } from '@/composables/languageSwitcher'

  const emit = defineEmits(['refreshAllData'])

  const avLocales = computed(() => availableLocales())
  const onLangChange = (value: string) => {
    setPathByLocale(value)
    emit('refreshAllData', value)
  }
</script>

<template>
  <LanguageSwitcher
    :languages="avLocales"
    @change="onLangChange"
  />
</template>
