import type MenuI from '@/types/Navigation/MenuI'
import type MenuItemI from '@/types/Navigation/MenuItemI'
import fetchGql from '@/utils/fetchGql'
import { useCacheStorage } from '@/composables/useSessionStorage'
import { isEn } from "@/utils/helpers";

const { getCache } = useCacheStorage()

const query = gql`
  query MenuItems($language: String) {
    MenuItems(starts_with: $language, resolve_links: "url", by_slugs: "corporate/shared/about-us-menu") {
      items {
        content {
          items
        }
      }
    }
  }
`

const parseData = (data: any, locale: string) => {
  const result: MenuI[] = []
  let target = '_self'

  data[0].content.items.forEach((menuItem: MenuItemI) => {
    if (menuItem.subItems?.length) {
      const parsedItems: MenuI[] = []
      menuItem.subItems.forEach((item: MenuItemI) => {
        let key = formatSbPath(item.redirect?.story?.full_slug, locale) || '/'

        if (item.redirect?.linktype === 'url') {
          key = menuItem.redirect.url
        }

        if (menuItem.redirect?.target) {
          target = menuItem.redirect.target
        }

        parsedItems.push({
          title: item.title,
          key,
          target
        })
      })
      let key = formatSbPath(menuItem.redirect?.story?.full_slug, locale) || '/'

      if (menuItem.redirect?.linktype === 'url') {
        key = menuItem.redirect.url
      }

      if (menuItem.redirect?.target) {
        target = menuItem.redirect.target
      }

      result.push({
        title: menuItem.title,
        subItems: parsedItems,
        key,
        target
      })
    } else {
      let key = formatSbPath(menuItem.redirect?.story?.full_slug, locale) || '/'

      if (menuItem.redirect?.linktype === 'url') {
        key = menuItem.redirect.url
      }

      if (menuItem.redirect?.target) {
        target = menuItem.redirect.target
      }

      result.push({
        title: menuItem.title,
        key,
        target
      })
    }
  })

  return result
}

export const useFetchMenu = () => {
  const fetchMenu = async (language: string) => {
    const cache = await getCache(`company-menu-${language}`)

    const variables = {
      language: isEn(language) ? '' : `${language}/*`
    }

    if (cache.value) return cache.value

    return fetchGql(query, variables).then(async (response: any) => {
      return (cache.value = parseData(response.MenuItems.items, variables.language))
    })
  }

  return { fetchMenu }
}
