import { useAppStore } from '@/stores'

interface LocaleI {
  code: string
  dir: 'ltr' | 'rtl'
  files: string[]
  isActive?: boolean
  iso: string
  key: string
  label: string
  title: string
}

const DEFAULT_LOCALE: LocaleI = {
  code: 'en',
  dir: 'ltr',
  files: ['locales/en.js'],
  iso: 'en-US',
  key: 'en',
  label: 'English',
  title: 'English'
}

function availableLocales(): LocaleI[] {
  const { $i18n } = useNuxtApp();
  const { locales, locale } = $i18n 
  const appStore = useAppStore()

  const avLoc: LocaleI[] = (locales.value as LocaleI[]).filter(
    (loc: LocaleI) => loc.code && appStore.availableLocales.includes(loc.code)
  )
  return avLoc.map((loc: LocaleI) => ({ ...loc, isActive: loc.code === locale.value }))
}

function selectedLocale(): LocaleI {
  const { locales, locale } = useI18n()
  const selected: LocaleI | undefined = (locales.value as LocaleI[]).find(
    (loc) => loc.code === locale.value
  )

  return selected || DEFAULT_LOCALE
}

function setPathByLocale(value: string) {
  const { push } = useRouter()
  const appStore = useAppStore()
  const path = appStore.slugs[value] ?? `/${value}`

  push(path)
}

export { availableLocales, setPathByLocale, selectedLocale }
